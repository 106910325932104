import React from 'react'
import Box from 'components/Box'
import useGlobalStyles from 'hooks/useGlobalStyles'

function Layout (props) {
  const { children } = props
  useGlobalStyles()
  return (
    <>
      <Box as="main" css="overflow-x: hidden;">
        {children}
      </Box>
    </>
  )
}

export default Layout
